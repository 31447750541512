import React, { useMemo } from 'react';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { useStore } from 'store';

import { Modal } from 'components/feedback';
import { Typography } from 'components/dataDisplay';
import { Button } from 'components/inputs';

import styles from './MegaBoxLockedDeskModal.module.scss';
import { isNil } from 'lodash';

const MegaBoxLockedDeskModal: React.FC = () => {
  const {
    megaBoxMarketingStore: { lockedDeskModalConfig, closeLockedDeskModal },
  } = useStore();
  const { t } = useTranslation();

  const handleClose = () => {
    closeLockedDeskModal();
  };

  const { requiredDesk, requiredCategory } = useMemo(() => {
    if (isNil(lockedDeskModalConfig)) return { requiredCategory: '', requiredDesk: '' };
    const categories = ['Standard', 'Business', 'Premium', 'VIP'];
    const { desk } = lockedDeskModalConfig;

    return {
      requiredDesk: ['1', '5'].includes(desk.name)
        ? t('megaBoxMarketingPage.card.requirements.first')
        : t('megaBoxMarketingPage.card.requirements.previous'),
      requiredCategory:
        desk.name === '1'
          ? categories[Math.max(0, categories.findIndex((category) => category === desk.blockName) - 1)]
          : desk.blockName,
    };
  }, [lockedDeskModalConfig, t]);

  return (
    <Modal className={styles.MegaBoxLockedDeskModal} isOpen={!!lockedDeskModalConfig} onClose={handleClose}>
      <Typography className={styles.title} variant="heading6" element="h2">
        {t('modals.megaBoxLockedDesk.title')}
      </Typography>

      <Typography className={styles.text} element="p">
        {t('modals.megaBoxLockedDesk.text', { category: requiredCategory, desk: requiredDesk })}
      </Typography>

      <Button className={styles.button} onClick={handleClose}>
        {t('modals.megaBoxLockedDesk.button')}
      </Button>
    </Modal>
  );
};

export default observer(MegaBoxLockedDeskModal);
