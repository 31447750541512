import React, { useMemo, useState } from 'react';

import { observer } from 'mobx-react';
import numbro from 'numbro';
import { Trans, useTranslation } from 'react-i18next';

import { ApiError, DeskListItem4, MegaBoxMarketingService } from 'api/client';
import { useStore } from 'store';

import { Typography } from 'components/dataDisplay';
import { Modal } from 'components/feedback';
import { Button, TextField } from 'components/inputs';

import { ReactComponent as MinusIcon } from 'static/icons/minus.svg';
import { ReactComponent as PlusIcon } from 'static/icons/plus.svg';

import styles from './PurchaseMegaBoxPlacesModal.module.scss';
import { MegaBoxLevels } from 'types/megaBoxMarketing';
import { sleep } from 'utils/flow';

const PurchaseMegaBoxPlacesModal: React.FC = () => {
  const {
    layoutStore,
    accountingStore: { getBalance },
    megaBoxMarketingStore: {
      purchasePlacesModalConfig,
      closePurchasePlacesModal,
      getDesks,
      getDesk,
      getDeskByPlaceId,
      desksGroupedByBlocks,
    },
  } = useStore();
  const { t } = useTranslation();

  const [isSubmited, setIsSubmited] = useState(false);
  const [quantity, setQuantity] = useState<string>('1');
  const [isPurchaseLoading, setIsPurchaseLoading] = useState(false);

  const allowMultiple = useMemo(
    () =>
      (purchasePlacesModalConfig?.desk as DeskListItem4)?.canMultipleBuy ||
      purchasePlacesModalConfig?.desk.name === '5',
    [purchasePlacesModalConfig]
  );

  const clonesPrice = useMemo(
    () =>
      numbro((purchasePlacesModalConfig?.desk.price || 0) * (parseInt(quantity) || 0)).format({
        trimMantissa: false,
      }),
    [purchasePlacesModalConfig, quantity]
  );

  const incrementQuantity = () => {
    if (parseInt(quantity) < 100) {
      setQuantity((parseInt(quantity) + 1).toString());
    } else {
      setQuantity('100');
    }
  };

  const decrementQuantity = () => {
    if (parseInt(quantity) > 1) {
      setQuantity((parseInt(quantity) - 1).toString());
    } else {
      setQuantity('1');
    }
  };

  const handleQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value) {
      setQuantity('');
      return;
    }

    const newValue = parseInt(event.target.value);
    setQuantity(Math.min(Math.max(1, newValue), 100).toString());
  };

  const handleClose = () => {
    setQuantity('1');
    setIsSubmited(false);
    closePurchasePlacesModal();
  };

  const handleSubmit = async () => {
    if (!purchasePlacesModalConfig) return;
    try {
      setIsPurchaseLoading(true);
      await MegaBoxMarketingService.buyMegaBoxPlaces(purchasePlacesModalConfig.desk.id, {
        quantity: parseInt(quantity),
      });
      await sleep(2000);
      getDesks();
      getBalance();

      if (purchasePlacesModalConfig.clones && purchasePlacesModalConfig.placeId) {
        getDeskByPlaceId(purchasePlacesModalConfig.placeId);
      } else if (purchasePlacesModalConfig.clones) {
        getDesk(purchasePlacesModalConfig.desk.id);
      }
    } catch (error) {
      const apiError = error as ApiError;
      layoutStore.openFeedbackModal(apiError?.body?.message || t('shared.errors.unknownError'));
    } finally {
      setIsPurchaseLoading(false);
      handleClose();
    }
  };

  const desksGroup = desksGroupedByBlocks?.[purchasePlacesModalConfig?.desk.blockName as MegaBoxLevels] ?? [];
  console.log(desksGroup);

  return (
    <Modal
      className={styles.PurchaseMegaBoxPlacesModal}
      variant="unwrapped"
      isOpen={!!purchasePlacesModalConfig}
      onClose={handleClose}
    >
      {!isSubmited && (
        <>
          <Typography className={styles.title} variant="heading6" element="h2" color="boneWhite">
            {!!purchasePlacesModalConfig?.clones
              ? t('modals.megaBoxPurchaseDesk.titleClones')
              : t('modals.megaBoxPurchaseDesk.titlePlaces')}
          </Typography>

          {allowMultiple && (
            <Typography className={styles.text} variant="paragraph1" element="p" color="boneWhite">
              <Trans
                i18nKey="modals.megaBoxPurchaseDesk.label"
                components={{ br: <br /> }}
                values={{
                  entity: t(!!purchasePlacesModalConfig?.clones ? 'shared.plurals.clones' : 'shared.plurals.places', {
                    count: 5,
                  }),
                }}
              />
            </Typography>
          )}

          {purchasePlacesModalConfig?.withBonus && (
            <Typography className={styles.text} variant="paragraph1" element="p" color="boneWhite">
              {t('modals.megaBoxPurchaseDesk.hintBonus')}
            </Typography>
          )}

          {allowMultiple && (
            <div className={styles.counter}>
              <Button className={styles.decrement} variant="outlined" color="lightGray" onClick={decrementQuantity}>
                <MinusIcon className={styles.icon} />
              </Button>

              <TextField className={styles.input} value={quantity} onChange={handleQuantityChange} />

              <Button className={styles.increment} variant="outlined" color="lightGray" onClick={incrementQuantity}>
                <PlusIcon className={styles.icon} />
              </Button>
            </div>
          )}

          <Typography className={styles.label} variant="paragraph1" element="p" color="boneWhite">
            {t('modals.megaBoxPurchaseDesk.price')}:
          </Typography>
          <Typography className={styles.price} variant="heading6" element="p" color="boneWhite">
            {clonesPrice} <span>ВВ</span>
          </Typography>

          {purchasePlacesModalConfig?.withBonus && (
            <>
              <Typography className={styles.label} variant="paragraph1" element="p" color="boneWhite">
                {t('modals.megaBoxPurchaseDesk.bonusMatrixPrice')}:
              </Typography>
              <Typography className={styles.price} variant="heading6" element="p" color="boneWhite">
                {numbro(desksGroup[desksGroup.length - 1]?.price).format({
                  trimMantissa: false,
                })}{' '}
                <span>ВВ</span>
              </Typography>

              <Typography className={styles.label} variant="paragraph1" element="p" color="boneWhite">
                {t('modals.megaBoxPurchaseDesk.total')}:
              </Typography>
              <Typography className={styles.price} variant="heading6" element="p" color="boneWhite">
                {numbro(
                  (desksGroup[desksGroup.length - 1]?.price ?? 0) + parseFloat(clonesPrice.replace(' ', ''))
                ).format({
                  trimMantissa: false,
                })}{' '}
                <span>ВВ</span>
              </Typography>
            </>
          )}

          <div className={styles.controls}>
            <Button variant="outlined" onClick={handleClose} color="lightGray">
              {t('shared.controls.cancel')}
            </Button>

            <Button disabled={!parseInt(quantity)} onClick={() => setIsSubmited(true)}>
              {t('modals.megaBoxPurchaseDesk.purchase')}
            </Button>
          </div>
        </>
      )}

      {isSubmited && (
        <>
          <Typography className={styles.clonesToPurchase} variant="heading6" element="p" color="boneWhite">
            <Trans
              i18nKey="modals.megaBoxPurchaseDesk.confirmation.title"
              components={{ br: <br />, span: <span /> }}
              values={{
                quantity,
                entity: t(!!purchasePlacesModalConfig?.clones ? 'shared.plurals.clones' : 'shared.plurals.places', {
                  count: parseInt(quantity),
                }),
              }}
            />
          </Typography>

          <Typography className={styles.clonesToPurchase} variant="paragraph1" element="p" color="boneWhite">
            {t('modals.megaBoxPurchaseDesk.confirmation.text', {
              quantity,
              price: purchasePlacesModalConfig?.withBonus
                ? numbro(
                    (desksGroup[desksGroup.length - 1]?.price ?? 0) + parseFloat(clonesPrice.replace(' ', ''))
                  ).format({
                    trimMantissa: false,
                  })
                : clonesPrice,
              entity: t(!!purchasePlacesModalConfig?.clones ? 'shared.plurals.clones' : 'shared.plurals.places', {
                count: parseInt(quantity),
              }),
            })}
          </Typography>

          <div className={styles.controls}>
            <Button
              variant="outlined"
              onClick={() => setIsSubmited(false)}
              color="lightGray"
              disabled={isPurchaseLoading}
            >
              {t('shared.controls.cancel')}
            </Button>

            <Button onClick={handleSubmit} isLoading={isPurchaseLoading}>
              {t('shared.controls.confirm')}
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};

export default observer(PurchaseMegaBoxPlacesModal);
